
<template>
<div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                     <b-form-group label="Filter by Status" class="mb-2" v-slot="{ ariaDescribedby }">
                        <b-form-radio-group
                            id="radio-group-2"
                            v-model="defaultStatus"
                            :aria-describedby="ariaDescribedby"
                            name="radio-default-status"
                        >
                            <b-form-radio  value="all">All</b-form-radio>
                            <b-form-radio  value="draft">Draft</b-form-radio>
                            <b-form-radio  value="active">Active</b-form-radio>
                            <b-form-radio  value="delivered">Delivered</b-form-radio>
                            <b-form-radio  value="completed">Completed</b-form-radio>
                            <b-form-radio  value="cancelled">Cancelled</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </div>
                <div class="card-body">
                     <div class="row mb-2">
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center">
                                    <span class="mr-1">Display</span>
                                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                                </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                          <div class="form-inline float-sm-right">
                            <div id="tickets-table_filter" class="dataTables_filter mr-sm-2 text-md-right">
                                <label class="d-inline-flex mb-0 align-items-center">
                                    <span class="mr-2 d-none d-sm-inline">Search:</span>
                                    <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm"></b-form-input>
                                </label>
                            </div>
                             <b-dropdown right variant="light" size="sm">
                              <template slot="button-content">
                                   <i class="mdi mdi-filter-variant"></i>
                              </template>
                             <b-dropdown-item-button @click="selectAllRows()">
                               <i :class="isSelectAll? 'mdi mdi-playlist-remove' : 'mdi mdi-playlist-check'" ></i> {{isSelectAll? 'DeSelect All' : 'Select All' }}
                               </b-dropdown-item-button>
                              <!-- <b-dropdown-item-button @click="startOrder()">
                                <i class="mdi mdi-calendar-multiple-check"></i> Confimed
                              </b-dropdown-item-button> -->

                              <b-dropdown-item-button >
                                <i class="mdi mdi-delete-forever-outline"></i> Delete</b-dropdown-item-button>
                            </b-dropdown>
                          </div>
                        </div>
                    </div>

                    <!-- Table -->
                    <div class="table-responsive mb-0">
                        <b-table striped hover table-class="table font-sm table-centered w-100" thead-tr-class="" ref="selectableTable" :items="orders" :fields="columns" responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                            <template v-slot:cell(check)="data">
                                <div class="custom-control custom-checkbox text-center">
                                    <input v-model="selected" type="checkbox" :value="data.item.id" class="custom-control-input" :id="`checkorder${data.item.id}`" />
                                    <label class="custom-control-label" :for="`checkorder${data.item.id}`">{{data.item.id}}</label>
                                </div>
                            </template>

                            <template v-slot:cell(buyer)="data">
                                <p @click="navigateTo(data.item.id)" class="m-0 d-inline-block cursor-pointer align-middle" v-if="data.item.buyer">
                                    <span class="text-dark font-sm">{{ data.item.buyer.first_name }} {{ data.item.buyer.last_name }}</span>
                                </p>
                            </template>
                            <template v-slot:cell(price)="data">
                                ${{ data.item.amount | money_format }}
                            </template>
                            <template v-slot:cell(date)="data">
                                <p @click="navigateTo(data.item.id)" class="cursor-pointer">
                                  <span class="d-block">{{ data.item.created_at | date_time }} </span>
                                  <span class="mt-1" style="font-size: 10px;">{{ data.item.created_at | date_time(true) }}</span>
                                  </p>
                            </template>
                            <template v-slot:cell(action)="data">
                                <ul class="list-inline table-action m-0">
                                    <li class="list-inline-item">
                                        <a href="javascript:void(0);" @click="confirmDelete(data.item.id)" class="action-icon text-danger">
                                            <i class="mdi mdi-delete"></i></a>
                                    </li>
                                </ul>
                            </template>
                        </b-table>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="dataTables_paginate paging_simple_numbers float-right">
                                <ul class="pagination pagination-rounded">
                                    <!-- pagination -->
                                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

</template>

<script>

export default {
  name:"order-list-table",
  props:{
    orderList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      defaultStatus:"all",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      selected: [],
      isSelectAll: false,
      columns: [
          {
              key: "check",
              label: "ID"
          },
          {
              key: "buyer",
              sortable: true
          },
          {
              key: "price",
              sortable: true
          },
          {
              key: "date",
              label: "Date",
              sortable: true
          },
          "action"
      ],
    }
  },
  watch: {
    "$route.query":"refreshQueryStatus"
  },
  computed: {
    rows() {
        return this.orders.length;
    },
    orders() {
      return this.defaultStatus == "all"
            ? this.orderList
            : this.orderList.filter((order) => (order.status == this.defaultStatus))
    },
  },
  methods: {
    navigateTo(id){
      this.$router.push(`/orders/${id}`).catch(() => {});
    },
    refreshQueryStatus(){
        if(this.$route.query.status){
            this.defaultStatus = this.$route.query.status
        }
    },
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.orders.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
    },
    startOrder() {
      if(!this.selected.length){ this.alertError("You have not selected any item"); return}
      let text = `You are about to confirm ${this.selected.length} order${this.selected.length > 1 ? 's':'' } as paid`;
      Swal.fire({
        title: "Are you sure?",
        text: text,
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#1abc9c",
        cancelButtonColor: "#111",
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.value) {
          const formData = new FormData()
          formData.append('data', JSON.stringify({orders: this.selected}))
          this.resquestProcessor(formData, 'mark-as-started')
        }
      });
    },
    resquestProcessor(formData,url){
      this.$store.dispatch("changeLoaderValue",true)
      this.$http.post(`/orders/${url}`,formData)
      .then(response => {
        this.$store.dispatch("changeLoaderValue",false)
        if(response.data.success){
          this.$store.dispatch("orderList/fetchOrders")
          this.alertSuccess(response.data.success)
          this.resetData()
        }else{
          this.alertError(response.data.error)
        }
      }).catch(error => { console.log(error) })
    },
    confirmDelete(orderId){
      Swal.fire({
        title: "Are you sure?",
        text: "You want to delete an order permanently",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#111",
        confirmButtonText: "Delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/orders/${orderId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
                this.alertSuccess(response.data.success)
                this.$store.dispatch("orderList/DELETE_ORDER",orderId)
            }else{
              this.alertError(response.data.error)
            }
          }).catch(error => { console.log(error) })
        }
      });
    },
  },
  mounted() {
    this.totalRows = this.orders.length;
     this.refreshQueryStatus()
  },
}

</script>

<style lang="scss">
.font-sm{
  font-size:13px;
}
</style>
